@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,700i');

// Bootstrap library.
@import 'bootstrap';

// Base-theme overrides.
@import 'overrides';

// Base-theme overrides.
@import 'variable-overrides';

// Additional mixins.
@import 'user-mixins';

html,
body {
  min-height: 100%;
}
a,
.btn,
button,
.myanimated {
  .transition(all 300ms ease);
}
h1 {
  margin: 0.5em 0 1em 0;
  padding: 0;
}
h2,
h3,
h4,
h5 {
  margin: 0.8em 0 0.8em 0;
  padding: 0;
}
h2.block-title {
  margin: 0 0 1em 0;
  font-size: @font-size-h3;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  @media @tablet {
    font-size: @font-size-h2;
  }
  @media @wide {
    font-size: @font-size-h1;
  }
}
.clearfix {
  &:before,
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}
#admin-menu {
  font-size: @font-size-small;
  display: none;
  @media @tablet {
    display: block;
  }
  > div > .dropdown > li > a {
    padding: 2px 3px 3px;
    @media @normal {
      padding: 2px 10px 3px;
    }
  }
}
body.admin-menu {
  margin-top: 0 !important;
  @media @tablet {
    margin-top: 29px !important;
  }
}
.blue {
  color: @theme-color;
}
.light-blue {
  color: @theme-color2;
}
.graybtn {
  .btn;
  height: 36px;
  border: 1px solid @gray;
  background: @gray;
  color: @white;
  font-weight: 700;
  text-transform: uppercase;
  &:hover {
    background: darken(@gray, 5%);
    color: @white;
  }
}
.yellowbtn {
  .btn;
  height: 36px;
  border: 1px solid @yellow;
  background: @yellow;
  color: @gray-base;
  font-weight: 700;
  text-transform: uppercase;
  .border-radius(4px);
  &:hover {
    background: darken(@yellow, 5%);
    color: @white;
  }
}
.underlined {
  position: relative;
  padding-bottom: 1rem;
  &:after {
    height: 1px;
    width: 40px;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -20px;
    content: '';
    display: block;
    background: @theme-color2;
  }
}
.overlay {
  display: none;
  &.shadow {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    @media @normal {
      display: none;
    }
  }
}

.header {
  a {
    color: @gray-light;
  }
  .topbar {
    background: @gray-dark;
    color: @white;
    .myanimated;
    font-size: @font-size-small;
    padding: 60px 0 6px 0;
    .container {
      padding: 0;
    }
    a {
      color: @white;
    }
    .topbarcontacts {
      text-align: center;
    }
    .topbarcart {
      text-align: center;
      .cart-block-items {
        position: absolute;
        right: 20px;
        top: 24px;
        background: @gray-dark;
        padding: 0 20px 1em 20px;
        z-index: 10;
        display: block;
        tr {
          border-bottom: 1px solid @gray;
          td {
            padding: 0.25em;
            text-align: left;
          }
        }
        &.collapsed {
          display: none;
        }
        &:after {
          content: 'View cart';
          display: block;
          text-align: right;
          color: @white;
          text-decoration: underline;
          cursor: pointer;
          margin-top: 0.5em;
        }
      }
      .cart-block-summary {
        display: inline-block;
        padding: 0;
        margin: 0;
        &:before {
          display: inline;
          padding-right: 5px;
          content: 'My Cart:';
          font-weight: 700;
        }
        * {
          display: inline;
          padding: 0;
          margin: 0;
        }
        .cart-block-summary-items {
          text-decoration: underline;
          cursor: pointer;
          &:after {
            font-family: @font-family-awesome;
            content: ' \f107';
          }
        }
        .cart-block-summary-total {
          display: none;
        }
        .cart-block-summary-links {
          display: none;
          ul {
            padding: 0 0 0 5px;
            margin: 0;
            .cart-block-checkout {
              display: none;
            }
            li {
              border: none;
            }
          }
        }
      }
    }
    .topbarcart-new {
      .block-title {
        text-transform: none;
        font-size: @font-size-small;
        margin: 0;
        padding: 1em 0 0 0;
        text-align: center;
        line-height: 1.6;
        @media @tablet {
          text-align: right;
          padding: 0;
        }
        .cart-block-arrow {
          top: 6px;
        }
      }
      #cart-block-container {
        position: absolute;
        right: 0;
        top: 30px;
        background: @gray-dark;
        padding: 0 20px 1em 20px;
        z-index: 10;
        display: none;
        @media @tablet {
          right: 20px;
          top: 24px;
        }
        &.open {
          display: block;
        }
        .cart-block-items {
          display: table;
          width: 100%;
          &.collapsed {
            display: table;
          }
          tr {
            border-bottom: 1px solid @gray;
            td {
              padding: 0.25em;
              text-align: left;
            }
          }
        }
        .cart-block-summary {
          display: table;
          width: 100%;
          tr {
            &.cart-block-summary-links {
              border-top: 1px solid @gray;
            }
            td {
              padding: 0.25em;
              text-align: left;
              &:nth-last-child(1) {
                text-align: right;
              }
            }
          }
          .links {
            margin: 0.5em 0 0 0;
          }
        }
      }
    }
    .topbaruser {
      text-align: center;
      .nav {
        display: block;
        margin: 0;
        padding: 0;
        text-align: center;
        @media @tablet {
          text-align: right;
        }
        li {
          margin: 0;
          padding: 0;
          display: inline;
          background: none;
          margin-left: 0.6em;
          &:nth-last-child(2) {
            margin-left: 2em;
          }
          a {
            background: none;
            display: inline;
            padding: 0;
            margin: 0;
            &:hover {
              background: none;
            }
          }
        }
      }
    }
  }
  .toplogo {
    background: @theme-color;
    padding: 0.5em 0;
    .logo {
      text-align: center;
      margin-bottom: 0.5em;
      img {
        width: 80px;
        height: auto;
      }
    }
    .logo2 {
      display: none;
      font-size: @font-size-base;
      font-style: italic;
      line-height: 120%;
      font-weight: bold;
      ul {
        padding: 0 0 0 1em;
        margin: 0;
        li {
          margin: 0;
          padding: 0;
        }
      }
    }
    .topcontacts {
      padding: 1em 0;
      text-align: center;
      color: @theme-color4;
      a {
        color: @white;
        cursor: default;
        &:hover {
          text-decoration: none;
        }
      }
      h3 {
        font-size: @font-size-h2;
        margin: 0;
        color: @white;
        font-weight: 700;
      }
      p {
        margin: 0;
      }
    }
    .top-text {
      padding: 1em 0;
      li {
        color: @white;
        font-size: @font-size-large;
        color: @gray-lighter;
      }
    }
  }
  @media @tablet {
    .toplogo {
      background: @theme-color url(../images/topbg.jpg) no-repeat center center;
      background-size: 40%;
      .logo {
        img {
          width: 140px;
        }
      }
      .logo2 {
        display: block;
      }
      .topcontacts {
        text-align: right;
        padding-right: 0;
      }
      .topbaruser {
        text-align: right;
      }
      .topcontacts {
        padding: 2em 0;
      }
      .top-text {
        padding: 2em 0;
      }
    }
    .topbar {
      padding-top: 64px;
      .topbarcontacts {
        text-align: left;
      }
      .topbarcart,
      .topbarcart-new {
        text-align: right;
      }
    }
  }
  @media @normal {
    .topbar {
      padding: 0.7em 0.2em;
    }
    .toplogo {
      .topcontacts {
        font-size: @font-size-large;
        b,
        strong {
          font-size: @font-size-h3 !important;
        }
      }
      .logo2 {
        font-size: @font-size-large;
      }
    }
  }
  @media @wide {
    .toplogo {
      background-size: 50%;
      .logo2 {
        font-size: @font-size-h4;
      }
    }
  }
  @media @xwide {
    .toplogo {
      .topcontacts {
        h3 {
          font-size: @font-size-h1;
        }
        b,
        strong {
          font-style: @font-size-h4;
        }
      }
      .top-text {
        li {
          font-size: @font-size-h2;
        }
      }
    }
  }
  @media @xxlwide {
    .toplogo {
      background-size: contain;
    }
  }
}

.mobile_header {
  display: block;
  padding: 0.5em 20px;
  background: @theme-color;
  color: @white;
  vertical-align: middle;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  .box-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  @media @normal {
    display: none;
  }
  .menu-btn {
    display: inline-block;
    font-size: 18pt;
    font-weight: normal;
    margin-right: 0.5em;
    padding: 0.25em;
    border: 1px solid rgba(255, 255, 255, 0.33);
    .border-radius(3px);
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .header-title {
    display: inline-block;
    font-size: @font-size-large;
    line-height: 1;
    font-weight: 300;
    padding: 0.25em 0;
  }
}
.admin-menu {
  .mobile_header {
    @media @tablet {
      top: 28px;
    }
  }
}
.block-md-slider {
  position: relative;
  .clearfix;
  width: 100%;

  .md-objects {
    width: 100%;
    margin: 0 auto;
    .md-object {
      padding: 1em;
      font-size: @font-size-base;
      color: @white;
      text-align: center;
      box-sizing: border-box;
      width: 100% !important;
      line-height: 1.64;
      h2 {
        font-weight: 700;
        margin: 0 0 0.5em 0;
        padding: 0;
        font-size: @font-size-large;
        @media @tablet {
          font-size: @font-size-h2;
        }
        @media @normal {
          font-size: @font-size-h1;
        }
        @media @wide {
          font-size: @font-size-h1*1.2;
        }
        @media @xwide {
          font-size: 4em;
        }
      }
      h3 {
        font-weight: 300;
        margin: 0 0 0.5em 0;
        padding: 0;
        font-size: @font-size-base;
        @media @tablet {
          font-size: @font-size-large;
        }
        @media @normal {
          font-size: @font-size-h3;
        }
        @media @wide {
          font-size: @font-size-h2;
        }
        @media @xwide {
          font-size: @font-size-h1;
        }
      }
      .btn {
        background: none;
        color: @white;
        border: 2px solid @white;
        display: inline-block;
        width: auto;
        height: auto;
        padding: 0.75em 2em;
      }
    }
  }
  @media @tablet {
    .homepage-mob-pic {
      img {
        max-height: 280px;
      }
    }
    .md-objects {
      .md-object {
        padding-top: 4em;
      }
    }
  }
  @media @normal {
    margin-top: 1em;
    .md-objects {
      .md-object {
        padding-top: 4em;
      }
    }
  }
  @media @wide {
    .md-objects {
      .md-object {
        padding-top: 12em;
      }
    }
  }
}

.content {
  padding: 0 0 4em 0;
  position: relative;
  #main-content {
    @media @tablet {
      display: block;
      height: 50px;
      background: @gray-lightest;
      position: absolute;
      z-index: -10;
      width: 100%;
    }
  }
  .container {
    padding: 0;
    @media @normal {
      padding: 0 20px;
    }
  }
}
.not-front {
  .toplogo {
    display: none;
  }
  @media @normal {
    .toplogo {
      display: block;
    }
  }
}
.footer {
  padding: 3em 0;
  margin: 0;
  border: none;
  background: @gray-darken;
  color: @gray-light;
  .container {
    background: none;
  }
  .footer-block {
    margin-bottom: 2em;
    padding: 0;
    @media @tablet {
      padding: 0 0.5em;
    }
    @media @normal {
      padding: 0 @grid-gutter-width / 2;
      margin-bottom: 0;
    }
    &.masq {
      margin-top: 2em;
    }
    a {
      color: @white;
    }
    .fa {
      font-size: @font-size-h3;
    }
    h2,
    h3 {
      font-size: @font-size-base;
      text-transform: uppercase;
      margin: 0 0 1em 0;
      text-align: left;
      font-weight: 700;
      color: @white;
    }
    .nav {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        display: block;
        a {
          margin: 0;
          padding: 0 0 1em 0;
          background: none;
          color: @white;
        }
      }
    }
  }
}
.hp_block_menu {
  padding: 3em 0;
  .hp-menu-header {
    text-align: center;
    margin-bottom: 2em;
    h2 {
      color: @theme-color;
      margin: 0 0 1em 0;
      font-weight: 700;
      position: relative;
      .underlined;
    }
    p {
      width: 75%;
      margin: 0 auto;
    }
  }
  .view-homepage-menu {
    .hp-item {
      background: @white;
      text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;
      .myanimated;
      .hb-image {
        margin-bottom: 1em;
        img {
          display: inline;
          .myanimated;
          width: 50px;
          height: auto;
        }
      }
      .hb-title {
        margin: 0.5em 0;
        font-size: @font-size-h3;
        font-weight: 700;
      }
      .hb-link {
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 2em;
      }
      &:hover {
        background: @yellow;
        .hb-image {
          img {
            -webkit-filter: brightness(200%);
            filter: brightness(200%);
          }
        }
        .hb-title {
          color: @white;
        }
        .hb-body {
          color: @white;
        }
        .hb-link {
          a {
            color: @white;
          }
        }
      }
    }
  }
}
.content-col {
  padding: 0 20px;
  @media @normal {
    padding: 0 0 0 40px !important;
  }
}
.sidebar {
  display: block;
  position: fixed;
  width: 100%;
  min-height: 100%;
  z-index: 1001;
  left: -100%;
  top: 0;
  padding: 0;
  .myanimated;
  .sidebar_wrapper {
    .transition(all 300ms ease);
    position: absolute;
    width: calc(~'100% - 40px');
    height: 100%;
    left: -100%;
    background: @white;
    z-index: 10000;
    overflow-y: scroll;
  }
  .close {
    .transition(all 300ms ease);
    color: @theme-color;
    font-size: 16pt;
    width: 40px;
    height: 40px;
    background: @white;
    position: absolute;
    top: 0;
    right: 100%;
    padding: 4px 10px;
    z-index: 9999;
    .box-shadow(inset 0px 0px 10px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    opacity: 1;
    font-weight: normal;
    padding-top: 0.35em;
  }
  .clickme {
    position: absolute;
    z-index: 9998;
    height: 100%;
    width: 100%;
  }
  &.open {
    left: 0;
    top: 0;
    .sidebar_wrapper {
      left: 0;
    }
    .close {
      right: 0;
    }
  }
  .topbaruser,
  .mainmenu {
    padding: 0 20px;
    text-align: left;
    .nav {
      display: block;
      margin: 0;
      padding: 0;
      text-align: left;
      @media @tablet {
        text-align: right;
      }
      li {
        margin: 0;
        padding: 0;
        display: block;
        background: none;
        a {
          background: none;
          display: block;
          padding: 0.5em 0;
          margin: 0;
          color: @gray-dark;
          height: auto;
          &:hover {
            background: none;
          }
        }
        &.active {
          a {
            color: @gray-dark;
          }
        }
        &.dropdown {
          position: static;
          .dropdown-menu {
            position: relative;
            background: @gray-lighter;
            top: 0;
            margin-left: 0;
            width: 100%;
            padding: 1em;
            margin: 0 0 0.5em 0;
            .clearfix;
            li {
              position: relative;
              width: 100%;
              text-align: left;
              display: block;
              float: none;
              border: none;
              padding: 0;
              box-sizing: border-box;
              a {
                border-bottom: none;
                color: @gray-dark;
                height: auto;
                display: block;
                white-space: pre-wrap;
                padding: 0.5em 0;
                margin: 0;
                line-height: 1.6;
                font-size: @font-size-small;
                background: none;
                text-align: left;
                height: auto;
                &:hover {
                  text-decoration: underline;
                }
                &:before {
                  display: none;
                }
              }
            }
          }
          &:hover {
            .dropdown-menu {
              display: block;
              li {
                a {
                  color: @gray-dark;
                }
              }
            }
          }
        }
      }
    }
  }
  .sidebar-block {
    border: 1px solid @gray-more-light;
    border-top: none;
    padding: 0 20px 20px 20px;
    .border-radiuses(0px, 3px, 3px, 0px);
    background: @gray-lightest;
    margin-bottom: 2em;
    h2 {
      font-size: @font-size-base;
      text-transform: uppercase;
      color: @white;
      background: @gray-light;
      padding: 0.5em 20px;
      margin: 0 -20px;
      text-align: center;
      margin-bottom: 20px;
    }
    &.vehicle-search-block {
      border: none;
      padding: 0;
      background: none;
      .btn {
        display: block;
        width: 100%;
      }
    }
    &.sidebar-search {
      h2 {
        height: 50px;
        background: #9e9e9e;
        font-size: @font-size-large;
        padding-top: 15px;
        text-align: center;
      }
      .views-exposed-widgets {
        margin: 0;
      }
      #edit-search-api-views-fulltext-wrapper {
        width: 75%;
        float: left;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        * {
          margin: 0;
        }
        input {
          .border-radiuses(0, 0, 4px, 4px);
          border-right: 0;
        }
      }
      .views-submit-button {
        width: 25%;
        float: left;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        button {
          .yellowbtn;
          margin: 0;
          width: 100%;
          font-size: 0;
          padding: 0.5em 1em;
          .border-radiuses(4px, 4px, 0, 0);
          &:before {
            display: block;
            height: 36px;
            content: '\f002';
            font-family: @font-family-awesome;
            font-size: @font-size-large;
            font-weight: normal;
          }
        }
      }
    }
    form {
      margin: 0;
      padding: 0;
      .form-item,
      .form-group {
        margin: 0 0 1em 0;
        padding: 0;
      }
      #form-checkout-cart-button-wrapper {
        margin-bottom: 1em;
        margin-left: 1em;
        float: right;
        button {
          display: inline-block;
          margin-bottom: 0;
          text-align: center;
          vertical-align: middle;
          touch-action: manipulation;
          cursor: pointer;
          white-space: nowrap;
          padding: 6px 12px;
          font-size: 10.5pt;
          line-height: 1.6;
          border-radius: 4px;
          height: 36px;
          border: 1px solid #555;
          background: #555;
          color: #fff;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
      .form-checkout-button-wrapper {
        float: right;
        a {
          display: inline-block;
          margin-bottom: 0;
          font-weight: normal;
          text-align: center;
          vertical-align: middle;
          touch-action: manipulation;
          cursor: pointer;
          background-image: none;
          border: 1px solid transparent;
          white-space: nowrap;
          padding: 6px 12px;
          font-size: 10.5pt;
          line-height: 1.6;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          transition: all 300ms ease;
          -webkit-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          height: 36px;
          border: 1px solid #fdd100;
          background: #fdd100;
          color: #000;
          font-weight: 700;
          text-transform: uppercase;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
          -moz-background-clip: padding;
          -webkit-background-clip: padding-box;
          background-clip: padding-box;
        }
      }
      .form-item-platenumber {
        width: 75%;
        float: left;
        box-sizing: border-box;
      }
      #edit-number-search {
        .yellowbtn;
        width: 20%;
        float: left;
        box-sizing: border-box;
        padding: 0;
        margin: 0 0 1em 5%;
        font-size: 0;
        border: none;
        &:before {
          display: block;
          height: 36px;
          content: '\f002';
          font-family: @font-family-awesome;
          font-size: @font-size-large;
          padding-top: 0.15em;
          font-weight: normal;
        }
      }
      .vehicle_search_submit {
        float: right;
        width: auto;
        display: inline-block;
        margin: 0 0 0 0.5em;
        a {
          .yellowbtn;
        }
      }
      .clear {
        float: right;
        width: auto;
        display: inline-block;
        margin: 0;
        button {
          .graybtn;
        }
      }
      .form-item-make {
        clear: both;
        padding-top: 1em;
        border-top: 1px solid @gray-lighter;
      }
    }
    &.vehicle-search-sidebar {
      label {
        display: none;
      }
    }
    &.quick-order-block {
      .edit-panes-add-parts-wrapper {
        margin: 0;
        .form-submit {
          .yellowbtn;
          float: right;
        }
      }
    }
  }
  @media @normal {
    z-index: 100;
    width: 25%;
    min-height: auto;
    left: 0;
    padding: 0;
    position: relative;
    .sidebar_wrapper {
      position: relative;
      width: 100%;
      min-height: auto;
      left: 0;
      overflow-y: visible;
      height: auto;
    }
    &:after {
      display: none;
    }
    .close {
      display: none;
    }
    .clickme {
      display: none;
    }
    .topbar {
      .topsearch {
        display: block;
      }
    }
    &.collapsed {
      .topbar {
        padding: 0.5em 0;
      }
    }
  }
  @media @xwide {
    width: 16.666666%;
  }
}

.mainmenu {
  padding: 0;
  .nav {
    margin: 0;
    padding: 0;
    text-align: center;
    position: relative;
    li {
      margin: 0;
      padding: 0;
      display: inline-block;
      a {
        display: inline-block;
        height: 25px;
        padding: 2px 15px;
        color: @gray-base;
        margin: 0;
        &.search-btn {
          background: @theme-color4;
          color: @gray-base;
          margin-left: 0;
          font-weight: 700;
          text-transform: uppercase;
        }
        @media @tablet {
          height: 50px;
          padding: 14px 6px;
          &.search-btn {
            margin-left: 4rem;
          }
        }
        &:hover {
          background: @gray-base;
          color: @white;
        }
      }
      &.dropdown {
        position: static;
        .dropdown-menu {
          position: absolute;
          background: @gray-base;
          top: 25px;
          margin-left: 0;
          width: 100%;
          padding: 0;
          margin: 0;
          .clearfix;
          @media @tablet {
            top: 50px;
            width: 90%;
          }
          li {
            position: relative;
            width: 50%;
            text-align: center;
            display: block;
            float: left;
            border-right: 1px solid @gray-dark;
            border-bottom: 1px solid @gray-dark;
            padding: 20px 20px 10px 20px;
            box-sizing: border-box;
            a {
              border-bottom: none;
              color: @white;
              height: auto;
              display: block;
              white-space: pre-wrap;
              padding: 0;
              margin: 0;
              line-height: 1.2;
              font-size: @font-size-small;
              background: @gray-base;
              text-align: center;
              height: 100px;
              &:hover {
                text-decoration: underline;
              }
              &:before {
                position: relative;
                display: block;
                content: '';
                background-image: url(../images/mmicons.png);
                background-repeat: no-repeat;
                height: 40px;
                margin: 0 auto 8px auto;
              }
              &.mm-car {
                &:before {
                  background-position: 50% 0px;
                }
              }
              &.mm-scom {
                &:before {
                  background-position: 50% -40px;
                }
              }
              &.mm-com {
                &:before {
                  background-position: 50% -80px;
                }
              }
              &.mm-ind {
                &:before {
                  background-position: 50% -120px;
                }
              }
              &.mm-bike {
                &:before {
                  background-position: 50% -160px;
                }
              }
              &.mm-mar {
                &:before {
                  background-position: 50% -200px;
                }
              }
              &.mm-access {
                &:before {
                  background-position: 50% -280px;
                }
              }
              &.mm-oth {
                &:before {
                  background-position: 50% -240px;
                }
              }
            }
          }
        }
        &:hover {
          .dropdown-menu {
            display: block;
            li {
              a {
                color: @white;
              }
            }
          }
        }
      }
    }
  }
  @media @tablet {
    .nav {
      text-align: left;
      li {
        &.dropdown {
          .dropdown-menu {
            li {
              width: 25%;
            }
          }
        }
      }
    }
  }
  @media @xwide {
    .nav {
      li {
        &.dropdown {
          .dropdown-menu {
            li {
              a {
                font-size: @font-size-base;
              }
            }
          }
        }
      }
    }
  }
}
.store-finder-top {
  padding: 1em 0;
  position: relative;
  > p {
    padding: 0 0 0 1em;
    margin: 0;
    text-align: center;
    @media @tablet {
      text-align: right;
      padding: 0;
    }
    a {
      color: @gray-base;
      font-weight: 700;
      position: relative;
      background: url(/sites/default/files/branch-icon.png) no-repeat 0px 3px;
      padding: 6px 0 4px 35px;
    }
  }
}

.region-hp-block-menu {
  padding: 1em 0 0 0px;
  .hp-text-block {
    border-top: 1px solid #eee;
    padding-top: 15px;
    padding-left: 40px;
    margin-top: 20px;
    .clearfix;
    p {
      text-align: center;
      font-style: italic;
      font-size: @font-size-h4;
      font-weight: bold;
      line-height: 1.2;
      &:nth-last-child(1) {
        color: @theme-color;
        a {
          color: @theme-color;
        }
      }
      &:nth-last-child(2) {
        img {
          max-width: 44px;
          width: 100%;
          height: auto;
        }
      }
    }
    @media @tablet {
      p {
        text-align: left;
        float: left;
        width: 50%;
        &:nth-last-child(1) {
          width: 30%;
        }
        &:nth-last-child(2) {
          width: 20%;
          text-align: center;
          img {
            max-width: 64px;
          }
        }
      }
    }
    @media @wide {
      p {
        font-size: @font-size-h4;
        padding-top: 20px;
        &:nth-last-child(2) {
          padding-top: 0;
          img {
            max-width: 80px;
          }
        }
      }
    }
    @media @xwide {
      p {
        font-size: @font-size-h4;
        padding-top: 30px;
        &:nth-last-child(2) {
          padding-top: 0;
          img {
            max-width: 96px;
          }
        }
      }
    }
  }
}
.hp-menu-item {
  padding: 0;

  box-sizing: border-box;
  border: 10px solid #fff;
  .myanimated;
  background: #ffc829;

  .hp-m-image {
    padding: 8px 0 0 15px;
    text-align: left;
    float: left;
    width: 75px;
  }
  .hp-m-title {
    text-align: right;
    padding: 25px 10px 15px 20px;
    width: 95%;
    line-height: 20px;
    height: 72px;

    a {
      padding: 10px 0;
      font-size: 14pt;
      text-transform: uppercase;
      font-weight: bold;
      color: @gray-base;
    }
  }
  .hp-m-body {
    background: #fff;
    padding: 5px 0;
    color: #666;
  }
  .hp-m-link {
    margin-top: 0.5em;
    a {
      color: @gray-base;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  &:hover {
    background: @theme-color4;
    .hp-m-image {
      img {
        -webkit-filter: brightness(10%);
        filter: brightness(10%);
      }
    }
  }
}

.copyright {
  padding: 1em;
  background: lighten(@gray-darken, 5%);
  color: @white;
  .nav {
    margin: 0;
    padding: 0;
    text-align: right;
    li {
      margin: 0 0 0 20px;
      padding: 0;
      display: inline-block;
      a {
        padding: 0;
        margin: 0;
        color: @white;
        display: inline;
        &:hover {
          background: none;
        }
      }
    }
  }
}
.node-product {
  .group-descr {
    .display-price {
      float: none;
      clear: both;
      text-align: left;
    }
  }
  .add-to-cart {
    padding-bottom: 1em;
    margin-bottom: 1em;
    /*border-bottom:1px solid @gray-lighter;*/
  }
  .group-details {
    margin-top: 1em;
    color: @gray-light;
    .group-sizes {
      margin-top: 1em;
      .clearfix;
      .field {
        float: left;
        display: inline-block;
        margin-right: 2em;
      }
    }
  }
  &.node-teaser {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 2em;
    h2 {
      font-size: @font-size-large;
      text-transform: uppercase;
      margin: 0 0 1em 0;
    }
    footer {
      display: none;
    }
    .product-image {
      padding: 0;
      margin: 0;
      float: none;
      clear: both;
    }
    @media @tablet {
      width: 50%;
    }
    @media @normal {
      width: 33.333333%;
    }
    @media @xwide {
      width: 25%;
    }
  }
}
.adaptive-table {
  position: relative;
  .view-content {
    z-index: 1;
    position: relative;
    background: @white;
  }
  .views-row {
    cursor: pointer;
    &:hover,
    &.activerow {
      background: @gray-lightest;
    }
    .node {
      .clearfix;
      padding: 0.5em 0;
      box-sizing: border-box;
      border-bottom: 1px solid @gray-lighter;
      > div {
        float: none;
        box-sizing: border-box;
        padding: 0.15em 0;
        .clearfix;
        border-bottom: 1px dotted @gray-lightest;
        &.field-name-body {
          font-weight: 700;
          text-transform: uppercase;
          font-size: @font-size-large;
        }
        .field-label {
          float: left;
          padding: 0 0.25em;
          box-sizing: border-box;
          width: 35%;
        }
        .field-items {
          float: left;
          padding: 0 0.25em;
          box-sizing: border-box;
          width: 65%;
        }
        &.js-hide {
          background: @white;
          width: 100%;
          .label-above {
            font-size: @font-size-large;
            border-bottom: 1px solid @gray-lightest;
            padding: 0.5em 0 0.25em 0;
          }
          .node-product {
            width: 100%;
            text-align: left;
            margin-bottom: 1em;
            border-bottom: 1px solid @gray-lightest;
            > div {
              float: none;
              box-sizing: border-box;
              padding: 0.15em 0;
              .clearfix;
              border-bottom: 1px dotted @gray-lightest;
              font-size: @font-size-base;
              .field-items {
                float: none;
                padding: 0;
                box-sizing: border-box;
                width: 100%;
              }
              &.field-name-uc-product-image {
                float: left;
                width: 20%;
                margin: 0 5% 0 0;
                padding: 0;
                img {
                  width: 100%;
                  height: auto;
                }
              }
              &.field-name-title {
                float: left;
                width: 75%;
                padding: 1em 0 0 0;
                margin: 0;
                * {
                  display: block;
                  text-align: left;
                  padding: 0;
                  margin: 0;
                }
              }
              &.field-name-body,
              &.model {
                display: block;
                width: 100%;
                clear: both;
                float: none;
                text-align: left;
                padding: 0 0 0.5em 0;
                margin: 0;
                font-weight: 400;
              }
              &.field-name-body {
                padding-top: 1em;
              }
              &.field-name-popup-product-link,
              &.field-name-open-new-tab-product-link,
              &.add-to-cart {
                display: inline-block;
                margin: 0 1em 0 0;
                .colorbox-node {
                  .graybtn;
                }
                .node-add-to-cart {
                  .yellowbtn;
                }
              }
            }
          }
        }
        @media @tablet {
          float: left;
          padding: 0 0.25em;
          border: none;
          white-space: normal;
          overflow: hidden;
          .contextual-links-wrapper {
            float: none;
          }
          .field-label {
            display: none;
          }
          .field-items {
            float: none;
            width: 100%;
          }
          &.field-name-body {
            font-size: @font-size-base;
            width: 15%;
          }
          &.field-name-field-vehicle-make {
            width: 10%;
          }
          &.field-name-field-vehicle-model {
            width: 8%;
          }
          &.field-name-field-vehicle-type {
            width: 25%;
          }
          &.field-name-field-vehicle-engine {
            width: 12%;
          }
          &.field-name-field-vehicle-year {
            width: 13%;
          }
          &.field-name-field-vehicle-cc {
            width: 7%;
          }
          &.field-name-field-vehicle-serial-no {
            width: 12%;
          }
          &.js-hide {
            .node-product {
              > div {
                float: left;
                padding: 0 0.25em;
                text-transform: none !important;
                .field-items {
                  float: none;
                  padding: 0;
                  box-sizing: border-box;
                  width: 100%;
                }
                &.field-name-uc-product-image {
                  float: left;
                  width: 5%;
                  margin: 0;
                  padding: 0 0.25em;
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
                &.field-name-title {
                  float: left;
                  width: 18%;
                  padding: 0 0.25em;
                  margin: 0;
                  h2 {
                    font-size: @font-size-base;
                    font-weight: 700;
                  }
                  * {
                    display: block;
                    text-align: left;
                    padding: 0;
                    margin: 0;
                  }
                }
                &.field-name-body,
                &.model {
                  width: 20%;
                  clear: none;
                  float: left;
                  padding: 0 0.25em;
                }
                &.field-name-body {
                  padding-top: 0;
                }
                &.field-name-popup-product-link,
                &.field-name-open-new-tab-product-link {
                  width: 15%;
                }
                &.add-to-cart {
                  width: 22%;
                }
                &.field-name-popup-product-link,
                &.field-name-open-new-tab-product-link,
                &.add-to-cart {
                  display: block;
                  float: left;
                  margin: 0;
                  .colorbox-node {
                    .graybtn;
                  }
                  .node-add-to-cart {
                    .yellowbtn;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.activerow {
      .js-hide {
        display: block;
      }
    }
  }
  .view-header {
    @media @tablet {
      background: @gray-lighter;
      .field-label {
        display: block !important;
        padding: 0 !important;
        width: 100% !important;
      }
    }
  }
}
.view-id-colorbox_popup,
.vehicle-search-colorbox-popup {
  .more-details {
    .graybtn;
  }
  .group-stock {
    h3 {
      margin: 20px 0 5px 0;
    }
  }
  .group-descr {
    h3 {
      font-size: 12pt;
      margin: 20px 0 5px 0;
      padding: 0;
      font-weight: 700;
      text-decoration: underline;
    }
  }
  .group-summary {
    h3 {
      font-size: 12pt;
      margin: 30px 0 5px 0;
      padding: 0;
    }
  }
  .group-cart {
    h3 {
      font-size: 22pt;
      margin: 15px;
      padding: 0;
    }
    .display-price {
      display: block;
      clear: both;
      float: none;
      margin-right: 0;
      text-align: left;
      .uc-price {
        &:before {
          content: 'RRP: ';
        }
        &:after {
          content: ' (excl GST)';
          font-weight: 400;
          font-size: @font-size-base;
        }
      }
    }
    .sell-price {
      font-weight: bold;
      color: #f5410f;
      margin-bottom: 4px;
      text-align: left;
      &::after {
        display: inline-block;
        content: '(excl GST)';
        width: 100%;
        font-weight: normal;
      }
    }
  }
  h2 {
    margin: 0.2em 0 1em 0;
    padding: 10px 0 10px 14px;
    background: #eee;
  }
  .node-product {
    .group-descr {
      .add-to-cart {
        .node-add-to-cart {
          .yellowbtn;
        }
      }
      .field-name-field-voltage,
      .field-name-field-assy,
      .field-name-field-holddown,
      .field-name-field-weight,
      .field-name-field-length,
      .field-name-field-width,
      .field-name-field-height {
        float: left;
        margin-right: 1em;
      }
      .field-name-more-details {
        float: none;
        .clearfix;
        .field-items {
          padding-top: 1em;
        }
        .more-details {
          .graybtn;
        }
      }
    }
  }
}
.easy-breadcrumb {
  margin: 0.5em 0 1em 0;
  padding: 0;
  font-size: @font-size-small;
  background: none;
  display: none;
  @media @tablet {
    display: block;
  }
}
.filter-reset-button {
  .block-facetapi;
  /*bottom: 20px;
	left:1em;
	position: absolute;
	z-index: 10;*/
  .btn-default {
    background: none;
    border: none;
  }
}
#block-facetapi-d6ilppaq6j3xrpugglgvionhphkdbnu0 {
  border-top: 1px solid @gray-more-light;
  border-radius: 3px 3px 0 0;
}
#block-block-21 {
  border-bottom: 1px solid @gray-more-light;
  border-radius: 0 0 3px 3px;
}
.block-facetapi {
  border-left: 1px solid @gray-more-light;
  border-right: 1px solid @gray-more-light;
  background: @gray-lightest;
  margin: 0;
  padding: 1em 1em 0 1em;
  color: @gray-base;
  box-sizing: border-box;
  &:nth-last-child(2) {
    padding-bottom: 1em;
    margin-bottom: 2em;
  }
  h2 {
    text-align: left;
    font-size: @font-size-base;
    padding: 0;
    margin: 0 0 0.25em 0;
  }
  .form-group {
    margin-bottom: 0;
  }
  /*@media @tablet{
		padding:1em;
		float:left;
		width:25%;
		margin-bottom:2em
	}*/
  &.size-changer {
    h2 {
      display: inline-block;
    }

    #search-api-ranges-block-slider-view-form-field-width {
      .text-range {
        position: absolute;
        top: 14px;
        left: 70px;
      }
      #edit-submit {
        .graybtn;
        margin-top: 1em;
        position: relative;
        float: right;
      }
    }
  }
  .views-exposed-widgets {
    margin: 0;
    .views-exposed-widget {
      float: none;
      width: 100%;
      padding: 0;
      margin: 0;
      label {
        text-align: left;
        font-size: @font-size-base;
        padding: 0;
        margin: 0 0 0.25em 0;
        text-transform: uppercase;
      }
    }
  }
}
.view-id-search_products,
.view-brands-landing-page,
.block-loan-stock-products,
.block-latest-bought-products {
  .view-content,
  #latest-bought-products {
    .clearfix;
  }
  .views-row,
  #latest-bought-products > form,
  #loan-stock-products-block > form {
    padding: 0 1em;
    float: left;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 2em;
    text-align: center;
    .ds-1col {
      background: @gray-lightest;
      padding: 1em;
    }
    .group-img {
      padding: 0;
      background: @white;
      height: 180px;
      img {
        width: 100%;
        height: auto;
        max-width: 100%;
        display: inline-block;
        height: 180px;
        object-fit: contain;
      }
    }
    .field-name-popup-product-link {
      padding: 0.25em 0;
      text-transform: uppercase;
      font-size: @font-size-large;
      a {
        color: @gray-dark;
      }
    }
    button[name='op'] {
      display: none;
    }
    .model {
      display: block;
      margin: 0;
    }
    .loan-stock-qty {
      * {
        display: inline-block;
      }
    }
    .group-cart {
      padding-top: 0.25em;
      .display-price {
        display: block;
        clear: both;
        float: none;
        margin-right: 0;
        * {
          display: inline;
          text-align: left;
        }
        .uc-price {
          &:before {
            content: 'RRP: ';
          }
          &:after {
            content: ' (excl GST)';
            font-weight: 400;
            font-size: @font-size-base;
          }
        }
      }
      .sell-price {
        font-weight: bold;
        color: #f5410f;
        margin-bottom: 4px;
        &::after {
          content: ' (excl GST)';
        }
      }
      .add-to-cart {
        display: block;
        padding: 0;
        margin: 0;
        border: none;
        .node-add-to-cart {
          .yellowbtn;
        }
      }
    }
    .btn-success.ajax-processed {
      .yellowbtn;
      top: -50px;
      z-index: 10;
      position: relative;
    }
    @media @tablet {
      width: 50%;
      height: 400px;
    }
    @media @wide {
      width: 33.333333%;
      height: 400px;
    }
    @media @xwide {
      width: 25%;
    }
  }
  #latest-bought-products > form {
    margin-bottom: -1em;
  }
}

.not-logged-in {
  .view-id-search_products,
  .view-brands-landing-page,
  .block-latest-bought-products {
    .views-row,
    #latest-bought-products > form {
      @media @tablet {
        height: 300px;
      }
    }
  }
}
.block-latest-bought-products {
  .node-add-to-cart {
    opacity: 0;
  }
}

.not-logged-in {
  .view-id-search_products,
  .view-brands-landing-page,
  .block-latest-bought-products {
    .views-row,
    #latest-bought-products > form {
      @media @tablet {
        height: 300px;
      }
    }
  }
}

.uc-product-node {
  .node-product {
    .group-sizes,
    .group-descr,
    .group-details,
    .group-codes,
    .group-img {
      margin: 0 0 1em 0;
      padding: 0;
    }
    .group-descr {
      .add-to-cart {
        padding: 0;
        margin: 0 0 2em 0;
        border: none;
        .node-add-to-cart {
          .yellowbtn;
        }
      }
    }

    @media @tablet {
      .group-sizes,
      .group-descr,
      .group-details,
      .group-codes,
      .group-img {
        margin: 0;
        padding: 0 20px;
      }
      .group-details {
        padding-top: 1em;
      }
      .group-sizes {
        > div {
          display: inline-block;
          margin-right: 1em;
        }
      }
      .field-name-field-webcode {
        .field-items {
          > div {
            display: inline-block;
            margin-right: 1em;
          }
        }
      }
    }
  }
}
#block-views-brands-page-block-block-1,
#block-views-brands-page-block-block-2,
#block-views-brands-page-block-block-3,
#block-views-brands-page-block-block-4,
#block-views-brands-page-block-block-5,
#block-views-brands-page-block-block-6,
#block-views-brands-page-block-block-7,
#block-views-brands-page-block-block-8 {
  h2.block-title {
    font-size: 18px;
    margin-top: 40px;
    background: #eee;
    padding: 10px;
  }
}

.view-brands-page-block {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-around;*/
    align-items: stretch;
    .brand-list-item {
      flex: 0 1 100%;
      padding: 0.5em;
      border: none;
      border-top: 1px solid @gray-lighter;
      border-collapse: collapse;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: stretch;
      &:nth-child(1) {
        border-top: none;
      }

      .views-field-field-image {
        height: 100%;
        width: 100%;
        .field-content {
          height: 100%;
          width: 100%;
          a {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: stretch;
            height: 100%;
          }
        }
        img {
          /*width:calc(~'100% - 2em');*/
          width: 100%;
          height: auto;
          object-fit: contain;
          font-family: 'object-fit: contain;';
          max-width: 200px;
        }
      }
      @media @tablet {
        flex: 0 2 auto;
        border: 1px solid @gray-lighter;
        border-bottom: none;
        border-right: none;
        &:nth-child(1),
        &:nth-child(2) {
          border-top: none;
        }
        &:nth-child(2n + 1) {
          border-left: none;
        }
      }
      @media @wide {
        flex: 0 3 auto;
        border: 1px solid @gray-lighter;
        border-bottom: none;
        border-right: none;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          border-top: none;
        }
        &:nth-child(2n + 1) {
          border-left: 1px solid @gray-lighter;
        }
        &:nth-child(4n + 1) {
          border-left: none;
        }

        .views-field-field-image {
          img {
            /*width:calc(~'100% - 2em');*/
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
  }
}
.brands {
  border-top: 5px solid @theme-color;
  padding: 2em 0;
  .view-content {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-around;*/
    align-items: center;
    > div {
      flex: 1 1 auto;
      text-align: center;
      img {
        height: auto;
        object-fit: contain;
        display: inline-block;
      }
    }
  }
}
.view-brands-landing-page {
  border-top: 1px solid @gray-lighter;
  margin-top: 2em;
  padding-top: 2em;
  #views-exposed-form-brands-landing-page-page {
    margin-bottom: 2em;
    .views-exposed-widgets {
      margin: 0;
      text-align: right;
      .views-exposed-widget {
        display: inline-block;
        float: none;
        clear: none;
        padding: 0;
        margin: 0;
        .views-widget {
          display: inline-block;
          margin-left: 0.5em;
        }
      }
    }
  }
}
.google-store-locator-map-container {
  padding-top: 1em;
  .google-store-locator-panel {
    border: none;
    .storelocator-filter {
      padding-bottom: 1em;
      .location-search {
        padding: 0;
        h4 {
          font-size: @font-size-base;
          text-transform: uppercase;
          font-weight: 700;
          margin: 0 0 0.5em 0;
        }
        input {
          border: 1px solid @gray-lighter;
          box-sizing: border-box;
          padding: 0.5em 1em;
          .border-radius(3px);
        }
      }
    }
    .store-list {
      li {
        padding: 0.1em;
        margin: 0 0 0.5em 0;
        width: 95%;
        .address {
          font-size: @font-size-small;
          margin: 0;
          line-height: 1.4;
        }
        .web {
          font-size: @font-size-small;
          margin: 0.25em 0 0 0;
          a {
            color: @gray-base;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.node-type-store-location {
  .node-store-location {
    margin-top: 2em;
    .group-img {
      border-right: 5px solid @gray-lightest;
      box-sizing: border-box;
      .field-item {
        margin-bottom: 1em;
        img {
          width: 100%;
          max-width: 480px;
          height: auto;
        }
      }
    }
    .group-descr {
      overflow: hidden;
      .field-name-field-store-open-hours {
        table {
          width: 100%;
        }
      }
    }
    .group-map {
      .geolocation-map {
        width: 100% !important;
        margin: 0;
      }
    }
  }
  .block-webform {
    h2 {
      font-size: @font-size-large;
      margin: 2em 0 1em 0;
      background: @gray-lightest;
      padding: 0.5em 1em;
      text-align: left;
    }
  }
}

.news-list-item {
  padding-bottom: 2em;
  a {
    color: @gray-dark;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    font-weight: 700;
  }
  .col-lg-2 {
    padding-bottom: 1em;
  }
}
#block-views-technical-block {
  .views-row {
    margin-bottom: 2em;
    h2 {
      margin: 0 0 0.5em 0;
    }
    .field-name-node-link {
      margin-top: 0.5em;
      a {
        color: @gray;
        text-decoration: underline;
      }
    }
  }
}
.auth0-lock.auth0-lock .auth0-lock-widget {
  width: 100% !important;
}
.auth0-lock.auth0-lock.auth0-lock-opened-in-frame {
  margin: 0 !important;
  width: 100% !important;
}
#auth0-login-form {
  min-height: auto !important;

  .auth0-login-link {
    .btn;
    .yellowbtn;
    width: 100%;
  }
}
#block-auth0-auth0-lock {
  margin-bottom: 2em;
  border: 1px solid @gray-lighter;
  border-radius: 4px;
  font-family: @font-family-base !important;
  .auth0-lock-cred-pane {
    background: @gray-lightest !important;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .auth0-lock-input-block {
    background: @white !important;
    .auth0-lock-input-wrap {
      background: @white !important;
    }
  }
}
.auth0-lock.auth0-lock .auth0-lock-header {
  height: auto !important;
  border-radius: 0 !important;
  padding: 0.5em @grid-gutter-width / 2 !important;
  background: @gray-light !important;
  color: @white !important;
  font-size: @font-size-base !important;
  font-family: @font-family-base !important;
}
.auth0-lock.auth0-lock .auth0-lock-header-bg {
  background: @gray-light !important;
  color: @gray-dark !important;
  .auth0-lock-header-bg-blur {
    background: none !important;
  }
}
.auth0-lock.auth0-lock .auth0-lock-header-welcome {
  font-size: @font-size-base !important;
  text-align: center !important;
  line-height: 1.1;
  &:before {
    content: 'My Account';
    color: @white;
    font-size: @font-size-base !important;
    font-weight: 700;
    text-transform: uppercase;
  }
  > * {
    display: none !important;
  }
}
.auth0-lock.auth0-lock .auth0-lock-submit {
  .btn;
  background: @yellow !important;
  color: @gray-dark !important;
  font-size: @font-size-base !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  height: auto !important;
  margin: 0 auto 20px auto !important;
  text-align: center !important;
  vertical-align: middle !important;
  touch-action: manipulation !important;
  cursor: pointer !important;
  border: 1px solid @yellow !important;
  white-space: nowrap !important;
  padding: 6px 12px !important;
  line-height: 1.6 !important;
  .border-radius(4px) !important;
  width: calc(~'100% - 40px') !important;
  letter-spacing: 0 !important;
  svg {
    display: none !important;
  }
  &:hover {
    font-size: @font-size-base !important;
    background: darken(@theme-color, 5%) !important;
    color: @white !important;
  }
}
.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit {
  height: auto !important;
  line-height: 1 !important;
}

#custom-cart-items-block {
  float: left;
  width: 100%;
  position: relative;
  form {
    padding: 0.5em 0;
    border-bottom: 1px solid @gray-lighter;
    .form-group {
      .clearfix;
      > * {
        display: inline-block;
      }
      .form-submit {
        font-size: 0;
        height: 24px;
        width: 24px;
        padding: 0;
        text-align: center;
        &:before {
          content: '\f00d';
          font-weight: normal;
          font-family: @font-family-awesome;
          font-size: @font-size-base;
        }
      }
      .node-product {
        float: left;
      }
      > div:not([typeof='sioc:Item foaf:Document']) {
        float: right;
        margin-right: 1em;
      }
    }
  }
}
.node-type-product {
  h3 {
    margin: 0.5em 0 0.5em 0;
  }
  .group-cart {
    display: flex;
    flex-direction: column;
    .display-price {
      width: 150px;
      text-align: left;
      padding: 0;
      .uc-price {
        &:before {
          content: 'RRP: ';
        }
        &:after {
          content: ' (excl GST)';
          font-weight: 400;
          font-size: @font-size-base;
        }
      }
    }
    .sell-price {
      font-weight: bold;
      color: #f5410f;
      margin-bottom: 8px;
      text-align: left;
      &::after {
        display: inline-block;
        content: '(excl GST)';
        font-weight: normal;
        width: 100%;
      }
    }
  }

  .node-product {
    .panel-default {
      border: none;
    }
    .field-name-field-substitue-codes {
      width: 100%;
      float: left;
      padding: 2em;
      .node-product {
        .clearfix;
        position: relative;
        padding: 0.5em 0;
        border-bottom: 1px solid @gray-lighter;
        .field-name-uc-product-image {
          float: left;
          width: 3em;
          position: absolute;
          left: 0;
          top: 0.5em;
          img {
            width: 100%;
            height: auto;
            max-height: 3em;
            object-fit: cover;
          }
        }
        .display-price {
          float: right;
          clear: none;
          padding: 1em 0;
          margin: 0;
          font-size: @font-size-base;
          width: 5em;
          text-align: right;
          box-sizing: border-box;
          line-height: 1;
        }
        header {
          width: calc(~'100% - 10em');
          box-sizing: border-box;
          float: left;
          h2 {
            margin: 0 0 0 4em;
            padding: 1em 0;
            font-size: @font-size-base;
          }
        }
      }
    }
    .group-spec {
      .panel-body {
        .clearfix;
        > div {
          float: left;
          width: 100%;
          box-sizing: border-box;
          border-bottom: 1px dotted @gray-lighter;
          padding: 0.5em 0 0em 0;
          .field-label {
            display: inline-block;
            float: left;
          }
          .field-items {
            display: inline-block;
            float: right;
          }
          @media @tablet {
            width: 48%;
            margin-right: 2%;
          }
          @media @wide {
            width: 31.333333%;
          }
        }
      }
    }
  }
}
.field-name-stock-info {
  padding: 0.5em 0;
  span {
    display: block;
    position: relative;
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      .border-radius(6px);
      margin-right: 0.5em;
      display: inline-block;
    }
    &.stock-green,
    &.national-green {
      &:before {
        background: #53b80f;
      }
    }
    &.stock-amber,
    &.national-amber {
      &:before {
        background: #f68908;
      }
    }
  }
}
#parts-table {
  .stock-green,
  .national-green {
    &:before {
      background: #53b80f;
      content: '';
      width: 12px;
      height: 12px;
      .border-radius(6px);
      margin-right: 0.5em;
      display: inline-block;
    }
  }
  .stock-amber,
  .national-amber {
    &:before {
      background: #f68908;
      content: '';
      width: 12px;
      height: 12px;
      .border-radius(6px);
      margin-right: 0.5em;
      display: inline-block;
    }
  }
}
.slick--skin--boxed-carousel {
  .slick-list {
    margin: 0;
  }
  .slide__content {
    text-align: center;
    border: none;
    margin: 0;
    padding: 0;
    &:hover {
      border: none;
      box-shadow: none;
    }
    li {
      list-style-type: none !important;
      margin: 10px 10px;
      min-width: 260px;
    }
    .slide__grid {
      float: none;
      display: inline-block;
    }
  }
}
.category-item {
  margin-bottom: 2em;
  .views-field-nothing {
    background: @gray-lightest;
    padding: 1em;
    text-align: center;
    a {
      color: @gray-dark;
      font-size: @font-size-large;
      img {
        margin-bottom: 1em;
        width: 100%;
        display: inline-block;
        height: auto;
        max-width: 240px;
        height: 180px;
        object-fit: cover;
      }
    }
  }
}

.view-downloads {
  .clearfix;
  .views-exposed-form .views-exposed-widget {
    float: none;
    padding: 0;
    margin-bottom: 2em;
  }
  .view-content {
    .clearfix;
    .downloads-item {
      margin-bottom: 2em;
      .views-field-nothing {
        background: @gray-lightest;
        padding: 1em;
        text-align: center;
        .title {
          padding: 0;
          a {
            font-size: @font-size-base;
          }
        }
        a {
          color: @gray-dark;
          font-size: @font-size-large;
          padding: 0 0.5em;
        }
        .file-icon,
        .file-size {
          display: none;
        }
        .field-content {
          > img {
            margin-bottom: 1em;
            width: 100%;
            display: inline-block;
            height: auto;
            max-width: 240px;
            height: 185px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
#google-store-locator-map-container {
  .clearfix;
  .google-store-locator-panel,
  .google-store-locator-map {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  @media @tablet {
    .google-store-locator-panel,
    .google-store-locator-map {
      width: 50%;
    }
  }
}
.home-slider {
  margin: 3em 0 0 0;
  .slick-arrow {
    &:before {
      color: @white;
    }
  }
}
.slick-track {
  .slide {
    .slide__content {
      position: relative;
      .field-name-field-slider-image {
        position: relative;
        z-index: 0;
      }
      .group-wrapper {
        position: absolute;
        width: 45%;
        margin: 0 5% 0 50%;
        z-index: 10;
        top: 0;
        h2 {
          margin: 1em 0;
        }
      }
    }
  }
}
.brands {
  .slick--skin--boxed-carousel .slide__content li {
    min-width: 260px;
    height: 100px;
    img {
      max-width: 90%;
      margin: 0 auto;
      height: 100%;
    }
    @media @tablet {
      min-width: 0;
      width: 16.666666%;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      float: left !important;
    }
  }
}
.uc-cart-checkout-form {
  #checkout-table {
    .delivered-qty {
      max-width: 68px;
    }
    td {
      width: 15%;
      &:nth-child(2) {
        width: 55%;
      }
    }
  }
}
.view-sliders {
  h2 {
    display: none;
  }
}
#customer_info_status {
  .btn-primary {
    margin: 0 0 0 0.5em;
  }
  .btn-default {
    background: @gray;
    color: @white;
    border-color: @gray;
    &:hover {
      background: @gray-dark;
    }
  }
}
.page-applications {
  .view-content {
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    flex-wrap: wrap;
    .app-item {
      flex: 1 1 100%;
      padding: 0 @grid-gutter-width / 2 2em @grid-gutter-width / 2;
      @media @tablet {
        flex: 1 1 50%;
      }
      .node-applications {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        header {
          order: -1;
          h2 {
            font-size: @font-size-large;
            margin: 1em 0 0.5em 0;
            a {
              color: @text-color;
            }
          }
        }
        .field-name-field-image {
          box-sizing: border-box;
          order: -2;
          img {
            display: inline-block;
          }
        }
        footer {
          padding-top: 1em;
          a {
            .graybtn;
          }
        }
      }
      @media @xwide {
        padding-bottom: 4em;
        .node-applications {
          flex-direction: row;
          flex-wrap: wrap;
          text-align: left;
          justify-content: flex-end;
          align-content: flex-start;
          header {
            flex: 0 1 55%;
            margin-left: auto;
            h2 {
              margin: 0 0 0.5em 0;
            }
          }
          .field-name-field-image {
            flex: 1 1 45%;
            padding-right: 1em;
          }
          .field-name-body {
            flex: 0 1 55%;
            margin-left: auto;
            margin-top: -100px;
          }
          footer {
            padding-top: 1em;
            flex: 0 1 55%;
            margin-left: auto;
          }
        }
      }
    }
  }
}
.view-partners {
  h2 {
    margin: 10px 0;
  }
  footer {
    margin-top: 10px;
  }
  .views-row {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dotted #ccc;
  }
  img {
    float: left;
    margin-right: 15px;
  }
}
.node-partners {
  img {
    margin-bottom: 25px;
  }
}
.page-header {
  padding-bottom: 7pt;
  margin: 22pt 0 10pt;
  border-bottom: 1px dotted #d4d4d4;
}

.page-basicsearch,
.page-vehiclesearch,
.page-advancedsearch {
  margin-bottom: 2rem;
  .basic-search-col {
    padding: 0 1rem 0 0;
    .form-item {
      position: relative;
      &:after {
        position: absolute;
        content: '\f002';
        font-family: @font-family-awesome;
        font-size: @font-size-large;
        color: @gray-lighter;
        right: 1rem;
        bottom: 0.15rem;
      }
      .form-control {
        background: @gray-lightest;
        height: 28px;
        padding: 3px 8px;
      }
    }
    .radio label,
    .checkbox label {
      min-height: 15px;
    }
    .mCSB_inside > .mCSB_container {
      margin-right: 0;
    }
    .mCSB_scrollTools {
      width: 8px;
    }
    .mCSB_scrollTools .mCSB_draggerRail,
    .mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 8px;
    }
    .basic-search-col-input {
      margin-bottom: 1rem;
    }
    .sticky-table {
      thead {
        display: none;
      }
      tr {
        width: 100%;
        display: block;
        background: none;
        line-height: 1.2;
        td {
          display: inline-block;
          padding: 3px 8px;
          border: none;
          font-size: @font-size-small*.9;
          line-height: 1;
          &:first-child {
            width: 40px;
            display: none;
          }
          &:last-child {
            width: 100%;
          }
        }
      }
    }
    .table-striped > tbody > tr {
      &:nth-of-type(odd) {
        background: none;
      }
      &:hover {
        background: lighten(@theme-color4, 25%);
      }
      &.active {
        > td {
          background: lighten(@theme-color4, 35%);
        }
      }
    }
  }
  .region-content {
    position: relative;
  }
  .vehiclesearch-input {
    .clearfix;
    text-align: right;
    @media @tablet {
      position: absolute;
      right: 0;
      top: -65px;
      width: 60%;
    }
    .form-item {
      display: inline-block;
      width: 90%;
      margin: 0;
      padding-right: 10px;
      vertical-align: middle;
      text-align: right;
      label {
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
      }
      input {
        display: inline-block;
        width: 100%;
        max-width: 190px;
        vertical-align: middle;
      }
      &:before {
        content: '\f1b9';
        font-family: @font-family-awesome;
        display: inline-block;
        padding-right: 10px;
        font-size: @font-size-h4;
        vertical-align: middle;
      }
    }
    #edit-vehiclesearch-button {
      display: inline-block;
      width: 10%;
      vertical-align: middle;
    }
  }
}
.page-vehiclesearch {
  #oscar-search-advanced-form {
    .basic-search-col,
    .big-search .form-item-submit,
    .content-search {
      display: none;
    }
  }
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
.ajax-progress {
  position: fixed;
  z-index: 1000000;
  background: @gray-trans;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .throbber {
    background: none;
    position: absolute;
    width: 100px;
    height: 100px;
    left: calc(~'50% - 50px');
    top: calc(~'50% - 50px');
    -webkit-animation: rotation 2s infinite linear;
    &:after {
      content: '\f110';
      display: block;
      line-height: 1;
      font-family: @font-family-awesome;
      font-size: 100px;
      color: @white;
    }
  }
}

.search-menu {
  .nav {
    margin: 1rem 0;
    li {
      display: inline-block;
      padding: 0;
      margin: 0 0.25rem 0 0;
      background: none;
      a {
        background: @gray-lightest;
        padding: 0.25rem 1rem;
        font-size: @font-size-small;
        color: @text-color;
        &.active {
          background: @gray-lighter;
        }
      }
    }
  }
}

#selected-details-table {
  padding: 2rem 0;
  table {
    width: 100%;
    margin: 0.5rem 0;
    thead {
      background: @gray-lightest;
      border-bottom: 1px solid @gray-lighter;
      tr {
        th {
          padding: 0.5rem;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid @gray-lighter;
        td {
          padding: 0.25rem 0.5rem;
        }
      }
    }
  }
}
#selected_vehicle {
  background: @theme-color3;
  color: @white;
  padding: 1rem;
  margin: 3rem 0;
  .border-radius(0.5rem);
  h2 {
    font-size: @font-size-h4;
    font-weight: 400;
    font-style: italic;
    margin: 0;
    padding: 0;
  }
}
#parts-table {
  .table-striped > tbody > tr:nth-of-type(odd) {
    background: none;
    border-bottom: 1px solid @gray-lighter;
  }
  .table-striped {
    td {
      vertical-align: middle;
    }
    img {
      width: 80px;
      height: 60px;
      object-fit: contain;
    }
    .btn {
      background: @gray-dark;
      color: @white;
      border: none;
    }
  }
}

.app {
  padding: 0 !important;
  width: 100% !important;
  .react-vehicle-search {
    margin-top: 2rem;
    table {
      width: 100%;
    }
  }
  .vs-category-filters {
    padding: 0;
    td {
      width: 16.666666%;
      padding: 0 1rem 0 0;
      box-sizing: border-box;
    }
    .control-label {
      display: none;
    }
    .form-group {
      position: relative;
      margin: 0 0 1rem 0;
      &:after {
        position: absolute;
        content: '\f002';
        font-family: @font-family-awesome;
        font-size: @font-size-large;
        color: @gray-lighter;
        right: 1rem;
        bottom: 0;
      }
      .form-control {
        background: @gray-lightest;
        height: 28px;
        padding: 3px 8px;
      }
    }
  }
}
.vs-select-lists {
  font-size: @font-size-small*0.85;
  select {
    width: calc(~'100% - 1rem');
    font-size: @font-size-small*0.9;
    border: 1px solid @gray-lighter;
    height: 280px;
    option {
      padding: 0.15rem 0.25rem;
    }
  }
}

.vs-btn-clear {
  button,
  button:hover,
  button:active,
  button:active:focus {
    color: #dbb500;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 138.69%;
  }
}

.vehiclesearch-input {
  .clearfix;
  text-align: right;
  @media @tablet {
    position: absolute;
    right: 0;
    top: -65px;
    width: 60%;
  }
  .form-item {
    display: inline-block;
    width: 90%;
    margin: 0;
    padding-right: 10px;
    vertical-align: middle;
    text-align: right;
    label {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
    }
    input {
      display: inline-block;
      width: 100%;
      max-width: 190px;
      vertical-align: middle;
    }
    &:before {
      content: '\f1b9';
      font-family: @font-family-awesome;
      display: inline-block;
      padding-right: 10px;
      font-size: @font-size-h4;
      vertical-align: middle;
    }
  }
  #edit-vehiclesearch-button {
    display: inline-block;
    width: 10%;
    vertical-align: middle;
  }
}

.vs-selected-vehicle-name {
  background: @theme-color3;
  color: @white;
  padding: 1rem;
  margin: 3rem 0;
  .border-radius(0.5rem);
  font-size: @font-size-h4;
  font-weight: 400;
  font-style: italic;

  > span {
    padding-right: 1.5rem;
    margin-right: 0;
    label {
      padding-right: 1rem;
    }
  }
}

.vs-results-table {
  table {
    width: 100%;
    tr {
      border-bottom: 1px solid @gray-lighter;
    }
    .thead {
      border-bottom: 2px solid @gray-lighter;
      th {
        padding: 0.25rem 0.5rem;
      }
    }
    td {
      vertical-align: middle;
      padding: 0.5rem 1rem;
      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
      button {
        .btn;
        .graybtn;
      }
    }
  }
}

.vs-results-table {
  .vs-results-header-drupal {
    display: none;
    border-bottom: 2px solid @gray-lighter;
    padding: 0.5rem 1rem;
    font-weight: 700;
    text-transform: uppercase;
    .clearfix;
    > div {
      display: inline-block;
    }
    @media @tablet {
      display: block;
    }
  }
  .vs-results-row {
    border-bottom: 0.5rem solid @gray-more-light;
    &:nth-child(even) {
      background: @white;
    }
    &:nth-child(odd) {
      background: @gray-lighter;
    }
    > div {
      display: block;
      > div {
        .clearfix;
        vertical-align: middle;
        > div {
          display: inline-block;
          vertical-align: middle;
          padding: 1rem;
          img {
            width: 100px;
            height: 100px;
            object-fit: contain;
          }
          button {
            .btn;
            .graybtn;
          }
        }
      }
    }
  }
  .vs-results-addtocart,
  .vs-results-note {
    width: 100%;
  }
  .vs-results-pic {
    cursor: pointer;
  }
  @media @tablet {
    .vs-results-pic {
      width: 15%;
    }
    .vs-results-partnum {
      width: 15%;
    }
    .vs-results-note {
      width: 25%;
    }
    .vs-results-price {
      width: 15%;
    }
    .vs-results-stock {
      width: 15%;
    }
    .vs-results-addtocart {
      width: 15%;
    }
  }
}
.react-plate-search {
  margin-top: 2rem;
  .clearfix;
  .generic-text-input {
    float: left;
    width: calc(~'100% - 170px');
    max-width: 300px;
    label {
      display: none;
    }
    .form-group {
      margin: 0;
    }
  }
  #run-plate-search,
  #edit-clear {
    width: 80px;
    float: left;
    margin-left: 5px;
  }
  #run-plate-search {
    .yellowbtn;
  }
}
.vs-plate-possiblity {
  td {
    padding: 0.15rem 0;
    border-bottom: 1px solid @gray-lightest;
    a {
      cursor: pointer;
    }
  }
}
.react-search-block {
  .react-vehicle-search {
    .flex-container();
    > div {
      .flex-element(0, 0, 0, 100%);
      margin-bottom: 1rem;
      padding: 1rem;
      .border-radius(5px);
      .vs-category-heading {
        display: block;
        text-transform: uppercase;
        font-weight: 700;
      }

      .vs-select-list {
        select {
          width: 100%;
          background: none;
          border: none;
          &:focus {
            border: none;
            outline: none;
          }
        }
      }

      .vs-category-filter {
        .help-block,
        .control-label {
          display: none;
        }
        .form-group {
          margin: 1rem 0;
          position: relative;
          .form-control {
            background: @gray-lighter;
            border-color: @gray-lighter;
            .box-shadow(none);
          }
          &:after {
            position: absolute;
            content: '\f002';
            font-family: @font-family-awesome;
            font-size: @font-size-large;
            color: @gray-more-light;
            right: 1rem;
            bottom: 0.5rem;
          }
        }
      }
      @media @tablet {
        //.flex-element(0, 0, 0, 16.666666%);
        flex: 1;
        padding: 0 1rem 0 0;
        .vs-category-filter {
          .form-group {
            .form-control {
              background: @gray-lightest;
              border-color: @gray-lightest;
            }
          }
        }
      }
    }
    .vs-category-greyed {
      background: @gray-lightest;
      .vs-select-list,
      .vs-category-filter {
        display: none;
      }
      @media @tablet {
        background: none;
        .vs-select-list,
        .vs-category-filter {
          display: block;
        }
      }
    }
    .vs-category-selected {
      background: @theme-color4;
      .vs-select-list,
      .vs-category-filter {
        display: none;
      }
      @media @tablet {
        background: none;
        .vs-select-list,
        .vs-category-filter {
          display: block;
        }
      }
    }
    .vs-category-open-selected {
      background: @theme-color4;
      @media @tablet {
        background: none;
      }
    }
    .vs-category-open {
      background: @gray-lightest;
      .vs-select-list,
      .vs-category-filter {
        display: block;
      }
      @media @tablet {
        background: none;
      }
    }
  }
  .vs-btn-clear {
    .btn {
      .yellowbtn;
    }
  }
}
.plate-search-btn {
  border: 1px solid #ffc829;
  background: #ffc829;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px 12px;
  font-size: 9.5pt;
  font-weight: bold;
  float: right;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  a {
    color: #333;
  }
}

/* New Vehicle Search */

.new-vehicle-search {
  float: none;
  width: 100%;
  @media @wide {
    float: left;
    width: 100%;
    //width: 82%;
  }
}

.new-vehicle-search-img {
  display: none;
  @media @wide {
    float: right;
    width: 18%;
    display: block;
    text-align: right;
    margin-top: 15px;
  }
}

.vs-category-heading {
  .vs-category-heading-static {
    display: none;
    @media @tablet {
      display: block;
    }
    @media @normal {
      display: block;
    }
    @media @wide {
      display: block;
    }
    @media @xwide {
      display: block;
    }
  }
  .vs-category-heading-changing {
    display: block;
    @media @tablet {
      display: none;
    }
  }
}
.node-type-vehicle-search {
  .sidebar {
    @media @normal {
      display: none;
    }
  }
  .content-col {
    @media @normal {
      width: 100%;
    }
  }
}
.closepopupblock {
  text-align: right;
  span {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0.5rem 0.5rem;
    &.closepopupbtn {
      width: 24px;
      height: 24px;
      background: #000;
      border-radius: 12px;
      color: @white;
      text-align: center;
      padding-top: 0.1rem;
      cursor: pointer;
    }
  }
}

.webform-client-form {
  .form-half {
    width: 100%;
    @media @normal {
      width: 48%;
      float: left;
      margin-right: 10px;
    }
  }
  textarea,
  .grippie {
    @media @normal {
      width: 97%;
    }
  }
}

.field-name-body {
  img {
    max-width: 100%;
  }
}

/*
======================================================
 Modal Dialog
======================================================
*/
.generic-modal {
  &.modal {
    .modal-dialog {
      position: relative;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .modal-header {
        .modal-title {
          font-weight: bold;
        }
      }

      .modal-content {
        position: relative;
        width: 100%;
        border: 8px solid rgba(0, 0, 0, 0.5);
      }
    }
  }
}

/*
======================================================
 Deep Cycle Battery Power Calculator
======================================================
*/
.page-deep-cycle-calculator {
  .deep-cycle-calculator-top {
    margin: 10px 0 20px;
    font-size: 16px;
    width: 70%;
    float: left;
  }
  #block-system-main {
    width: 70%;
    float: left;
  }

  .right-side-disclaimer {
    width: 25%;
    margin-left: 5%;
    float: right;
    padding: 20px 40px;
    background-color: #000;
    color: #fff;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    line-height: 1.5;

    h3 {
      color: #fac526;
      font-weight: 700;
      font-style: normal;
      font-size: 15px;
    }
  }
  @media screen and (max-width: ~'@{screen-xlg}') {
    .region-content {
      display: flex;
      flex-direction: column;
    }
    .deep-cycle-calculator-top,
    #block-system-main,
    .right-side-disclaimer {
      width: 100%;
      margin: 0;
    }

    .right-side-disclaimer {
      order: 4;
    }
  }
}

#deep-cycle-calculator-form {
  margin-top: 30px;

  > div > fieldset:nth-child(odd),
  #rows fieldset:nth-child(even) {
    input {
      background-color: #f9f9f9;
    }
  }
  fieldset {
    border: none;
    box-shadow: none;
    margin-bottom: 20px;
    padding-left: 50px;
    position: relative;

    &#edit-title {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 500;
      margin-bottom: 10px;
      min-height: 40px;
      word-break: break-word;
    }

    .panel-heading {
      position: absolute;
      width: 50px;
      left: 0;
      bottom: 0;
      background: transparent;
      border: 0;
    }

    .panel-body {
      display: flex;
      padding: 0;

      #row-title-device,
      .form-item[class*='-device'] {
        width: 34%;
        padding-right: 15px;
      }

      #row-title-number,
      .form-item[class*='-number'] {
        width: 22%;
        padding-right: 15px;
      }

      #row-title-watts,
      .form-item[class*='-watts'] {
        width: 22%;
        padding-right: 15px;
      }

      #row-title-hours,
      .form-item[class*='-hours'] {
        width: 22%;
      }
    }

    @media screen and (max-width: ~'@{screen-md}') {
      &#edit-title {
        font-size: 14px;
      }

      .panel-body {
        .form-group {
          margin-bottom: 0;
        }
        #row-title-device,
        .form-item[class*='-device'],
        #row-title-number,
        .form-item[class*='-number'],
        #row-title-watts,
        .form-item[class*='-watts'] {
          padding-right: 10px;
        }
      }
    }
  }
  .form-submit {
    background-color: transparent;
    color: #056ab2;
    border-color: transparent;
    padding-left: 0;
    font-size: 16px;
    font-weight: 400;

    .glyphicon-plus:before {
      content: '\2295';
      font-size: 22px;
    }
  }

  #calculate_result {
    display: flex;
    border-style: dotted;
    border-color: #dddddd;
    border-width: 1px 0 1px 0;
    justify-content: center;
    margin-top: 20px;
    > div {
      display: flex;
      flex-wrap: wrap;
      font-size: 16px;
      line-height: 1.2;
      font-weight: 400;
      color: #5c5c5c;
      padding: 20px 40px;
      justify-content: center;
      align-items: center;
      text-align: center;

      label {
        width: 100%;
      }
      span {
        font-size: 40px;
        font-weight: 500;
        padding-top: 10px;
        padding: 0 10px;
      }

      span.value {
        color: #1c1c1c;
      }
      span.calculate_result_unit {
        font-size: 20px;
      }
    }
  }

  #searched_product {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    .node-product {
      width: 30%;
      background: #f3f3f3;
      padding: 15px;
      text-align: center;
      position: relative;
      margin-bottom: 20px;
      display: inline-block;

      &:not(:last-child) {
        margin-right: 5%;
      }

      @media screen and (max-width: ~'@{screen-md}') {
        width: 94%;
        margin: 0 3% 20px;
        &:not(:last-child) {
          margin-right: 3%;
        }
      }

      .field-name-uc-product-image {
        img {
          height: 180px;
          object-fit: cover;
        }
      }

      .field-name-title {
        margin: 10px 0;
        a {
          color: #333333;
          font-size: 19px;
          text-decoration: none;
        }
      }

      .field-name-body {
        margin-bottom: 5px;
      }

      .product-info {
        display: inline-block;
        width: 100%;
      }

      .field-name-field-value-designation {
        .vocabulary-product-quality {
          h2 {
            position: absolute;
            top: 15px;
            left: 0;
            margin: 0;
            padding: 5px 25px 1px 15px;
            font-size: 18px;
            font-weight: 900;
            display: inline-block;
            box-sizing: border-box;
            height: 30px;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.45);

            &::after {
              content: '';
              position: absolute;
              right: -15px;
              top: 0;
              width: 0;
              height: 0;
              border-width: 15px 15px 15px 0;
              border-style: solid;
              border-right-color: transparent;
            }

            a {
              font-size: inherit;
              font-weight: inherit;
              pointer-events: none;
              text-transform: uppercase;
              color: #fff;
              line-height: 1.2;
            }
          }

          .content {
            display: none;
          }

          &.flooded h2 {
            background-color: #c8841f;
            &::after {
              border-top-color: #c8841f;
              border-bottom-color: #c8841f;
            }
          }
          &.agm h2 {
            background-color: #68ab3e;
            &::after {
              border-top-color: #68ab3e;
              border-bottom-color: #68ab3e;
            }
          }
          &.lead h2 {
            background-color: #2aa3bd;
            &::after {
              border-top-color: #2aa3bd;
              border-bottom-color: #2aa3bd;
            }
          }
        }
      }
    }
  }
}

.vsearch-navs {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > .btn {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  .feedback {
    color: @white;
    background-color: @blue;
    border-color: @blue;
  }
  // Responsive
  @media @tablet {
    margin-left: -1rem;
    justify-content: flex-end;
  }
}

// Update Fix : Product Search Result
.node-product {
  &.view-mode-search_result {
    display: flex;
    flex-direction: column;
    height: 100%;
    .group-cart {
      margin-top: auto;
    }
  }

  // Product Info Display Price animate on initial
  .product-info {
    &.display-price,
    &.sell-price {
      .myanimated;
      height: 0;
      visibility: hidden;
      opacity: 0;

      &.updated-price {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  // Add to Cart Button : Animate to avoid flickering
  .add-to-cart {
    .form-actions {
      .myanimated;
      height: 0;
      visibility: hidden;
      opacity: 0;
      &.actions-updated {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}


// Added Copied from newbattery town
.react-vehicle-search{
  .vs-select-list {
    height: 300px;
    overflow-y: auto;
  }
  .vs-select-option {
    font-size: 14px;
    color: #555;
    line-height: 1.4;
    &:hover,
    &.selected {
      background: #ddd;
      color: #222;
      cursor: pointer;
    }
  }
}

.mega-menu-search {
  &:not(.mega-menu-search__plate-search) {
    .react-plate-search {
      display: none;
    }
  }
}

#uc-cart-view-form{
  .form-type-uc-quantity{
    .form-text{
      min-width: 60px;
      text-align: center;
    }
  }
}

// End Added Copied from newbattery town