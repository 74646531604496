/**
 * Variable Overrides.
 *
 * Modify this file to override the Bootstrap Framework variables. You can copy
 * these variables directly from ../bootstrap/less/variables.less to this file.
 */

// Set the proper directory for the Bootstrap Glyphicon font.
@icon-font-path: '../bootstrap/fonts/';


@gray-base:              #000;
@gray-darken:            #202020;
@gray-dark:              #333333;
@gray:                   #555555;
@gray-light:             #989898;
@gray-more-light:        #a6a6a6;
@gray-lighter:           #d4d4d4;
@gray-lightest:          #f3f3f3;
@gray-trans: rgba(0, 0, 0, 0.25);
@white: #fff;

@body-bg: @white;

@blue: #1387ac;
@green: #729829;
@yellow: #ffc829;

@theme-color: @gray-base;
@theme-color2: @blue;
@theme-color3: @green;
@theme-color4: @yellow;


@text-color:            @gray-dark;
@link-color:            @theme-color2;
@link-hover-color:      darken(@link-color, 15%);

@font-family-sans-serif: 'Lato', sans-serif;
@font-family-awesome:  'FontAwesome';


@font-family-base:        @font-family-sans-serif;

@font-size-base:          10.5pt;
@font-size-large:         ceil((@font-size-base * 1.25));
@font-size-small:         ceil((@font-size-base * .9));
@font-size-h1:            floor((@font-size-base * 3.2));
@font-size-h2:            floor((@font-size-base * 2));
@font-size-h3:            ceil((@font-size-base * 1.8));
@font-size-h4:            ceil((@font-size-base * 1.5)); 
@font-size-h5:            ceil((@font-size-base * 1.35));
@line-height-base:        1.6;


@grid-gutter-width:         40px;
@screen-xlg:                  1500px;
@screen-xlg-min:              @screen-xlg;
@screen-xlg-desktop:          @screen-xlg-min;
@screen-xlg-max:               (@screen-xlg-min - 1);
@container-xlarge-desktop:      (1440px + @grid-gutter-width);
@container-xlg:                 @container-xlarge-desktop;